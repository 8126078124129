<template>
  <div class="collabora">
    <el-dialog
      :visible.sync="visible"
      width="40%"
      style="text-align: left"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
      @close="close"
      @open="open"
    >
      <p style="margin-left: 10px" class="collabora-head">
        {{ $t("canvas.btn.reviewRequest") }}
      </p>

      <!--主体部分  -->
      <!-- <div class="collabora-main"> -->
      <el-form
        status-icon
        style="text-align: left; margin: 10px"
        label-width="100px"
      >
        <!-- 搜索框 -->
        <div class="collabora-main-up">
          <el-formvalue-item
            label=""
            prop="accountIdList"
            class="collabora-main-up-1"
          >
            <el-select
              v-model="state1"
              multiple
              filterable
              default-first-option
              :placeholder="$t('canvas.reviewRequestForm.placeholder')"
            >
              <el-option
                v-for="(item, index) in options"
                :key="item.value"
                :value="item"
                :label="item.nickname"
              >
              </el-option>
            </el-select>
          </el-formvalue-item>
          <el-form-item class="items">
            <span> {{ $t("canvas.reviewRequestForm.note") }}</span>
          </el-form-item>
          <el-form-item class="items" label-width="0px">
            <el-button type="info" class="button-confirm" @click="handleSend">{{
              $t("btn.sendBtn")
            }}</el-button>
          </el-form-item>
        </div>

        <!--  -->
        <!-- 评审人员  -->
        <p style="margin-left: 10px">
          {{ $t("canvas.reviewRequestForm.title2") }}
        </p>
        <div class="collabora-main-dowm">
          <div class="owner">
            <!-- 应该由后端获取数据 -->
            <span class="owner-left"> yufan.wang@devops </span
            ><span class="owner-right">{{
              $t("canvas.floatWindow.creator")
            }}</span>
          </div>

          <div class="review">
            <div class="review-1">
              <el-tag
                v-for="avatar in downTags"
                :key="avatar"
                closable
                class="review-picture"
                @close="handleClose(avatar)"
              >
                <!-- <PersonalAvatar :src="avatar.src" :size="45" /> -->
                <personal-avatar
                  :size="45"
                  :avatar="avatar.avatar"
                  :nickname="avatar.nickname"
                  :colorid="avatar.accountId"
                ></personal-avatar>
                <span class="review-picture-name">
                  {{ avatar.nickname }}</span
                ></el-tag
              >
              <span class="reviewe-number">
                {{ downTags.length }}
                {{ $t("canvas.reviewRequestForm.num") }}</span
              >
            </div>
          </div>
        </div>
      </el-form>
      <!-- </div> -->
    </el-dialog>
  </div>
</template>

<script>
import PersonalAvatar from "../personal";
import { get_project_members } from "@/network/projectMember/index.js";
// 目前只暴露一个控制可见属性，其他功能封装在组件内部

export default {
  components: { PersonalAvatar },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collaborators: [],
      isSending: false,
      isOwner: false,
      options: [], // 接受返回来的用户列表
      state1: [], //保存的是选中的用户
      downTags: [], //这里保存的是评审者的数据
    };
  },
  created() {
    // const permission = this.$store.getters.permission;
    // if (permission === "owner") {
    //   this.isOwner = true;
    // }
    // console.log("trigger");
    // console.log(permission); get_allMember();
  },
  mounted() {},
  methods: {
    close() {
      //关闭弹窗
      // this.visible = false;
      this.$emit("update:visible", false);
      // this.resetForm("ruleForm");
    },

    open() {
      //打开弹窗
      this.get_allMember();
    },

    async get_allMember() {
      //获取项目内所有人员
      let res = await get_project_members(this.get_pid());
      // this.restaurants = res;
      res.forEach((element) => {
        //必须要加value属性  否则无法识别
        element.value = element.accountId;
      });

      this.options = res;
    },
    handleSend() {
      //发送                    //发送是向后端发送选中的用户  携带的是state1这个数组   //这里接口还没有做好
      this.downTags = this.downTags.concat(this.state1); //目前直接赋值给downTags了，后期需要改
      this.state1 = []; //发送后清空state1
    },

    handleClose(avatar) {
      //移除现有的评审人员      这里也需要后端的接口  每次触发都应该向后端发送请求
      this.downTags.splice(this.downTags.indexOf(avatar), 1);
      // this.restaurants.push(avatar);
    },
  },
};
</script>

<style lang="scss" scoped>
.items {
  text-align: right;
  margin: 5px;
}
.button-confirm {
  background-color: rgba(1, 76, 170, 1);
  color: #f8fafc;
}
.button-confirm:hover {
  background-color: #5596cb;
}
//
.collabora .el-dialog {
  display: flex;
  flex-direction: column;
}
//
.collabora-head {
  margin-top: -30px;
  font-size: 18px;
}
//

//
//搜索框
.collabora-main-up {
  ::v-deep .el-select {
    // margin-left: 0px !important;
    width: 100%;
  }
}

.collabora-main-up-1 {
  text-align: left;
  margin: 5px;
  width: 100%;
}

//
//评审人员
.collabora-main-dowm {
  margin-bottom: 20px;
  .owner {
    width: 100%;
    height: 35px;
    border: 1px solid rgb(126, 116, 121, 0.1);
    text-align: auto;
    line-height: 35px;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 6px rgba(0, 0, 0, 0.04);
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    .owner-left {
      margin-left: 15px;
    }
    .owner-right {
      margin-right: 15px;
    }
  }
  .review {
    width: 100%;
    height: 110px;
    border-radius: 4px;
    border: 1px solid rgb(126, 116, 121, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 6px rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 25px;
    overflow: hidden;
    position: relative;
  }
  .review-1 {
    width: 80%;
    overflow: auto;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 15px;
  }
  .review-picture {
    display: flex;
    margin-right: 3px;
    flex-direction: column;
    align-items: center;
    height: 78px;
    position: relative;
    ::v-deep .el-icon-close {
      position: absolute !important;
      top: 1px !important;
      right: 3% !important;
    }
  }
  .review-picture-name {
    margin-top: -10px;
  }
  .reviewe-number {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
.el-tag {
  color: black;
  background-color: white;
  border: 0px;
}
.el-avatar {
  border: 1px solid rgb(126, 116, 121, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 0 6px rgba(0, 0, 0, 0.04);
}
//
//
</style>
