<template>
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    :show-close="true"
    width="420px"
    height="300px"
    @close="handleFinish"
  >
    <div class="permission_dialog">
      <div style="display: inline-block">
        <el-dropdown trigger="hover" @command="roleClick">
          <span style="font-size: 16px">
            {{ role_type }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="column in role_type_list"
              :key="column"
              :command="column"
              :v-model="column"
              >{{ column }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div
        style="display: inline-block; padding-left: 14px; font-size: 16px"
        class="head-box"
      >
        {{ $t("addMyFilePermission.view") }}
        <el-checkbox />
        <span
          style="
            display: inline-block;
            transform: scale(0.5);
            vertical-align: -3px;
            margin-left: -5px;
          "
          >{{ $t("file.table.options.dialog.box1") }}</span
        >
      </div>
      <div
        style="
          display: inline-block;
          padding-left: 24px;
          padding-right: 15px;
          font-size: 16px;
        "
        class="head-box"
      >
        {{ $t("addMyFilePermission.edit") }}
        <el-checkbox />
        <span
          style="
            display: inline-block;
            transform: scale(0.5);
            vertical-align: -3px;
            margin-left: -5px;
          "
          >{{ $t("file.table.options.dialog.box1") }}</span
        >
      </div>
      <div style="display: inline-block; font-size: 16px" class="head-box">
        {{ $t("addMyFilePermission.download") }}
        <el-checkbox />
        <span
          style="
            display: inline-block;
            transform: scale(0.5);
            vertical-align: -3px;
            margin-left: -5px;
          "
          >{{ $t("file.table.options.dialog.box1") }}</span
        >
      </div>
    </div>
    <el-form ref="editPermissionForm" :model="form">
      <el-scrollbar style="height: 250px; width: 400px">
        <el-form-item
          v-for="item in form.role_arr"
          :key="item.roleName"
          label-width="50px;"
          label=""
          style="padding-left: 9px"
        >
          <el-row>
            <el-col :span="6">
              <div>
                <div style="float: left">
                  <el-avatar v-if="item.avatar == ''">{{
                    item.roleName
                  }}</el-avatar>
                  <el-avatar v-else src="item.avatar"></el-avatar>
                </div>
              </div>
              <div
                style="
                  text-align: left;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  show-overflow-tooltip: true;
                "
              >
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="item.roleName"
                >
                  <span slot="reference">&nbsp;{{ item.roleName }}</span>
                </el-popover>
              </div>
            </el-col>
            <el-col :span="18">
              <div class="box-group">
                <el-checkbox-group
                  v-model="item.permission"
                  style="display: inline-block"
                >
                  <el-checkbox label="view" name="permission"
                    ><br
                  /></el-checkbox>
                  <el-checkbox
                    label="edit"
                    name="permission"
                    style="padding-left: 26px; padding-right: 27px"
                    ><br
                  /></el-checkbox>
                  <el-checkbox label="down" name="permission"
                    ><br
                  /></el-checkbox>
                </el-checkbox-group>
              </div>
            </el-col>
          </el-row>
        </el-form-item>
      </el-scrollbar>
    </el-form>
    <div class="dialog-foot">
      <el-row>
        <el-col span="2">
          <i
            class="iconfont icon-add"
            style="float: left; font-size: 30px; display: inline-block"
          />
        </el-col>
        <el-col span="6">
          <el-input
            :v-model="add_role"
            :placeholder="$t('addMyFilePermission.info1')"
            style="display: inline-block"
          ></el-input>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    clickrow: {}, //接受传递的是哪一行
  },
  data() {
    return {
      role_type: this.$t("addMyFilePermission.role1"),
      role_type_list: [
        this.$t("addMyFilePermission.role1"),
        this.$t("addMyFilePermission.role2"),
      ], //用户职位列表
      add_role: "",
      form: {
        role_arr: [
          {
            avatar: "",
            roleName: "开发1号gfhftdfghdfhgdfhfdhdf",
            permission: ["view"],
          },
          {
            avatar: "",
            roleName: "开发2号",
            permission: ["edit"],
          },
          {
            avatar: "",
            roleName: "开发3号",
            permission: ["edit"],
          },
          {
            avatar: "",
            roleName: "开发4号",
            permission: ["edit"],
          },
        ],
      },
    };
  },
  methods: {
    roleClick(item) {
      //项目下拉列表点击事件
      this.role_type = item;
    },
    handleFinish() {
      this.visible = false;
      this.$emit("update:visible", false);
      //   this.resetForm("ruleForm");
    },
  },
};
</script>

<style scoped>
.permission_dialog {
  height: 6vh;
}
.head-box >>> .el-checkbox__inner {
  width: 10.5px;
  height: 10.5px;
}
.head-box >>> .el-checkbox__inner:hover {
  border-color: #30648f;
}
.head-box >>> .el-checkbox__inner::after {
  border: 1px solid #707070;
  border-left: 0px;
  border-top: 0px;
  height: 6px;
  left: 1.5px;
  position: absolute;
  top: 0px;
  width: 3.5px;
}
.head-box >>> .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: white;
  border-color: #707070;
}

.head-box >>> .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #707070;
}
/*  */
/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}
/deep/.el-checkbox__inner {
  width: 20px;
  height: 20px;
}
/deep/.el-checkbox__inner:hover {
  border-color: #30648f;
}
/deep/.el-checkbox__inner::after {
  border: 2px solid white;
  border-left: 0px;
  border-top: 0px;
  height: 13px;
  left: 5px;
  position: absolute;
  top: 0px;
  width: 6px;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #30648f;
  border-color: #30648f;
}
/deep/.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #30648f;
}

/*  */
.dialog-foot {
  height: 4vh;
  width: 100%;
  padding-top: 10px;
}
.dialog-foot >>> .el-input {
  font-size: 22px;
}
.dialog-foot >>> .el-input__inner {
  height: 32px;
  padding-left: 5px;
  font-size: 10px;
  border-color: #707070;
  font-colot: #707070;
}
.my-main >>> .el-dialog__header {
  padding: 0px;
}
</style>
>
