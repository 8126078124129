import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
export function moveFileIntoProject(projectId, fileKey, targetFileTypeId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/filesCopy/${fileKey}/fieldDetail`,
    params: {
      targetFileTypeId,
    },
    method: 'get'
  })
}


export function move(projectId, fileKey, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/filesCopy/${fileKey}`,
    data,
    method: 'post'
  })
}