
function getBlob(url) {
    return new Promise((resolve) => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(xhr.response)
            }
        }
        xhr.send()
    })
}
/**
*通过oss链接下载文件
*@param {string} 链接
*/
export const downloadLink = (url, totalUrl, name) => {
    if (name) {
        const link = document.createElement("a");
        getBlob(url).then((blob) => {
            link.href = window.URL.createObjectURL(blob);
            link.download = name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    } else {
        const link = document.createElement("a");
        link.style.display = "none";
        if (totalUrl) {
            link.href = url;
        } else {
            link.href = `https://automind-oss.ytdevops.com${url}`;
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

};