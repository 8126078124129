import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 添加用户
export function getUserCollections(type, pageNumber, pageSize) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/collections/${type}`,
    method: 'get',
    params: {
      page: pageNumber,
      size: pageSize
    },
  })
}
export function addIntoCollection(projectId, data) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/collections`,
    method: 'post',
    data: data,
  })
}
export function deleteCollection(projectId, data) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/collections`,
    method: 'delete',
    data: data,
  })
}