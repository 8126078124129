<template>
    <div>
        <el-dialog
        :title="$t(`file.table.options.addToBaseLine`)"
        :visible.sync="AddVisible"
        :close-on-click-modal="false"
        :show-close="true"
        width="25%"
        class="adddialog"
        @close="closeAddDialog"
        >
            <div
            v-loading="isAllBaseLineLoading"
            class="add-main__item add-main__header"
            >
                <label>{{ $t(`file.table.options.targetBaseLine`) + ': ' }}</label>
                <el-select
                    v-if="!isAllBaseLineLoading"
                    v-model="selectedBaseLine"
                    filterable
                    :placeholder="$t('placeholder.dropdown')"
                >
                    <el-option
                    v-for="(item, index) in allBaseLine"
                    :key="index"
                    :label="`${item.name} (${item.status_ch})`"
                    :value="item.baselineId"
                    >
                    </el-option>
                </el-select>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="info" @click="closeAddDialog()">{{
                $t("btn.cancelBtn")
                }}</el-button>
                <el-button type="primary" @click="add">{{
                $t("btn.addBtn")
                }}</el-button>
            </span>
        </el-dialog>
    
    </div>
</template>

<script>
import { get_baseline_list,add_files } from "@/network/baseline/index.js"

export default {
    data() {
        return {
            AddVisible: false,
            isAllBaseLineLoading: true,
            selectedBaseLine: "",
            allBaseLine: [],
            selectedFiles:[],
            fileKeyList:[]
        }
    },
    methods: {
        closeAddDialog() {
            this.AddVisible = false;
            this.isAllBaseLineLoading = true;
            this.allBaseLine = [];
            this.selectedBaseLine = ""
        },
        async add(){
            let _this = this
            let filesUidList = this.selectedFiles.map((items)=> items.file_id)
            add_files(this.get_pid(),this.selectedBaseLine,filesUidList).then((res)=>{
                _this.$message({
                    type: "success",
                    message: _this.$t("baseline.message.addSuccess"),
                });
                _this.closeAddDialog()
            })
        },
        switch_status(val) {
            switch (val) {
                case "ALREADY_STARTED":
                return this.$t("canvas.testPlan.status.started");
                case "NOT_STARTED":
                return this.$t("canvas.testPlan.status.notStarted");
            }
        },
        async init(item){
            this.fileKeyList = item.map((items) => items.file_key);
            this.AddVisible = true;
            this.selectedFiles = item
            let pid = this.get_pid()
            const res = await get_baseline_list(pid);
            const obj = {};
            const list = [
                ...(res.NOT_STARTED || []),
                ...(res.ALREADY_STARTED || []),
                ...(res.COMPLETE || []),
            ];
            list.forEach((item) => {
                obj[item.baselineId] = item;
            });
            let result = [];
            let property = ["ALREADY_STARTED", "NOT_STARTED"];
            property.forEach((type) => {
                if (res[type]) {
                    result = [...result, ...res[type]];
                }
            });
            this.allBaseLine = result.map((ele) => {
                ele.status_ch = this.switch_status(ele.status);
                return ele;
            });            
            this.isAllBaseLineLoading = false
        },
        
    },
}
</script>

<style scoped lang="scss">

.add-main__item {
    display: flex;
    justify-content: center;
    align-items: center;
    & > label{
        font-size: 16px;
        margin-right: 25px;
    }
}

</style>